import { Transition } from "@headlessui/react";
import { useState } from "react";

export const Features = () => {
  const [activeTab, setActiveTab] = useState<number>(1);

  return (
    <section className='relative'>
      <div
        className='absolute inset-0 pointer-events-none mb-16'
        aria-hidden='true'
      ></div>
      <div className='absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2'></div>

      <div className='relative max-w-6xl mx-auto px-4 sm:px-6 overflow-hidden'>
        <div className='pt-12 md:pt-20'>
          {/* Section header */}
          <div className='max-w-3xl mx-auto text-center pb-12 md:pb-16'>
            <h1 className='h2 mb-3'>Tailored Solutions for Your Needs</h1>
            <p className='text-xl text-gray-600'>
              Choose the Perfect Way to Generate Your PDFs
            </p>
          </div>

          {/* Section content */}
          <div className='md:grid md:grid-cols-12 md:gap-6'>
            {/* Content */}
            <div className='max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6'>
              {/* Tabs buttons */}
              <div className='mb-8 md:mb-0'>
                <a
                  id='features-inline-pdf'
                  className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${
                    activeTab !== 1
                      ? "bg-white shadow-md border-gray-200 hover:shadow-lg"
                      : "bg-gray-200 border-transparent"
                  }`}
                  href='#0'
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveTab(1);
                  }}
                >
                  <div>
                    <div className='font-bold leading-snug tracking-tight mb-1'>
                      Inline PDF
                    </div>
                    <div className='text-gray-600'>
                      Generate PDFs by merging templates with your data
                      instantly, ensuring efficient document creation
                    </div>
                  </div>
                  <div className='flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3'>
                    <svg
                      className='w-3 h-3 fill-current'
                      viewBox='0 0 12 12'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z' />
                    </svg>
                  </div>
                </a>
                <a
                  id='features-link-pdf'
                  className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${
                    activeTab !== 2
                      ? "bg-white shadow-md border-gray-200 hover:shadow-lg"
                      : "bg-gray-200 border-transparent"
                  }`}
                  href='#0'
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveTab(2);
                  }}
                >
                  <div>
                    <div className='font-bold leading-snug tracking-tight mb-1'>
                      Unbranded link to PDF
                    </div>
                    <div className='text-gray-600'>
                      Host PDFs on our datacenters, get unbranded links to share
                      with your users for seamless document access
                    </div>
                  </div>
                  <div className='flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3'>
                    <svg
                      className='w-3 h-3 fill-current'
                      viewBox='0 0 12 12'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z' />
                    </svg>
                  </div>
                </a>
                <a
                  id='features-s3-pdf'
                  className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${
                    activeTab !== 3
                      ? "bg-white shadow-md border-gray-200 hover:shadow-lg"
                      : "bg-gray-200 border-transparent"
                  }`}
                  href='#0'
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveTab(3);
                  }}
                >
                  <div>
                    <div className='font-bold leading-snug tracking-tight mb-1'>
                      Upload to your S3 bucket (Not available yet)
                    </div>
                    <div className='text-gray-600'>
                      Generate documents and upload to your AWS S3 bucket for
                      complete control and accessibility
                    </div>
                  </div>
                  <div className='flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3'>
                    <svg
                      className='w-3 h-3 fill-current'
                      viewBox='0 0 12 12'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z' />
                    </svg>
                  </div>
                </a>
                <a
                  id='features-callback-pdf'
                  className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${
                    activeTab !== 4
                      ? "bg-white shadow-md border-gray-200 hover:shadow-lg"
                      : "bg-gray-200 border-transparent"
                  }`}
                  href='#0'
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveTab(4);
                  }}
                >
                  <div>
                    <div className='font-bold leading-snug tracking-tight mb-1'>
                      Webhooks (Not available yet)
                    </div>
                    <div className='text-gray-600'>
                      Receive document links via webhooks in real-time,
                      providing access the moment they're generated
                    </div>
                  </div>
                  <div className='flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3'>
                    <svg
                      className='w-3 h-3 fill-current'
                      viewBox='0 0 12 12'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z' />
                    </svg>
                  </div>
                </a>
              </div>
            </div>

            {/* Tabs items */}
            <div className='max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1'>
              <div className='transition-all hidden md:block'>
                <div className='relative flex flex-col text-center lg:text-right'>
                  {/* Item 1 */}
                  <Transition
                    show={activeTab === 1}
                    appear={true}
                    className='w-full'
                    enter='transition ease-out duration-700 transform order-first'
                    enterFrom='opacity-0 translate-x-60'
                    enterTo='opacity-100 translate-x-0'
                    leave='transition ease-out duration-300 transform absolute'
                    leaveFrom='opacity-100 translate-x-0'
                    leaveTo='opacity-0 translate-x-0'
                    unmount={false}
                  >
                    <div className='relative inline-flex flex-col w-full rounded'>
                      <img
                        className='md:max-w-none w-[100%] lg:w-[80%] rounded border-[2px] border-slate-500'
                        src={"/images/template-html.png"}
                        alt='Features bg'
                      />
                      <img
                        className='md:max-w-none absolute w-[80%] lg:w-[60%] right-[10%] lg:right-0 top-48 lg:top-16 rounded border-[2px] border-slate-500'
                        src={"/images/template-json.png"}
                        alt='Features bg'
                      />
                      <img
                        className='md:max-w-none w-[100%] lg:w-[90%] relative left-0 lg:left-[5%] mt-[12rem] lg:-mt-8 rounded border-[2px] border-slate-500'
                        src={"/images/pdf-result.png"}
                        alt='Features bg'
                      />
                    </div>
                  </Transition>
                  {/* Item 2 */}
                  <Transition
                    show={activeTab === 2}
                    appear={true}
                    className='w-full'
                    enter='transition ease-out duration-700 transform order-first'
                    enterFrom='opacity-0 translate-x-60'
                    enterTo='opacity-100 translate-x-0'
                    leave='transition ease-out duration-300 transform absolute'
                    leaveFrom='opacity-100 translate-x-0'
                    leaveTo='opacity-0 translate-x-0'
                    unmount={false}
                  >
                    <div className='relative inline-flex flex-col w-full rounded'>
                      <img
                        className='md:max-w-none w-[100%] lg:w-[80%] rounded border-[2px] border-slate-500'
                        src={"/images/template-html.png"}
                        alt='Features bg'
                      />
                      <img
                        className='md:max-w-none absolute w-[80%] lg:w-[60%] right-[10%] lg:right-0 top-48 lg:top-16 rounded border-[2px] border-slate-500'
                        src={"/images/template-json.png"}
                        alt='Features bg'
                      />

                      <div className='md:max-w-none w-[100%] lg:w-[70%] relative left-0 lg:left-[15%] mt-[12rem] lg:-mt-3 rounded bg-blue-600 text-white text-left leading-7 font-semibold px-4 py-4'>
                        https://
                        <span className='font-bold'>
                          d1oukgrfj4kf7j.cloudfront.net
                        </span>
                        <br />
                        /ba7e0621-0d6b-4db7-aaa4-ac55855cc.pdf
                      </div>
                    </div>
                  </Transition>
                  {/* Item 3 */}
                  <Transition
                    show={activeTab === 3}
                    appear={true}
                    className='w-full'
                    enter='transition ease-in-out duration-700 transform order-first'
                    enterFrom='opacity-0 translate-x-60'
                    enterTo='opacity-100 translate-x-0'
                    leave='transition ease-out duration-300 transform absolute'
                    leaveFrom='opacity-100 translate-x-0'
                    leaveTo='opacity-0 translate-x-0'
                    unmount={false}
                  >
                    <div className='relative inline-flex flex-col w-full rounded'>
                      <img
                        className='md:max-w-none w-[100%] lg:w-[80%] rounded border-[2px] border-slate-500'
                        src={"/images/template-html.png"}
                        alt='Features bg'
                      />
                      <img
                        className='md:max-w-none absolute w-[80%] lg:w-[60%] right-[10%] lg:right-0 top-48 lg:top-16 rounded border-[2px] border-slate-500'
                        src={"/images/template-json.png"}
                        alt='Features bg'
                      />
                      <img
                        className='md:max-w-none w-[100%] lg:w-[70%] relative left-0 lg:left-[15%] mt-[12rem] lg:-mt-7 rounded border-[2px] border-slate-500'
                        src={"/images/aws-s3.png"}
                        alt='Features bg'
                      />
                    </div>
                  </Transition>
                  {/* Item 4 */}
                  <Transition
                    show={activeTab === 4}
                    appear={true}
                    className='w-full'
                    enter='transition ease-in-out duration-700 transform order-first'
                    enterFrom='opacity-0 translate-x-60'
                    enterTo='opacity-100 translate-x-0'
                    leave='transition ease-out duration-300 transform absolute'
                    leaveFrom='opacity-100 translate-x-0'
                    leaveTo='opacity-0 translate-x-0'
                    unmount={false}
                  >
                    <div className='relative inline-flex flex-col w-full rounded'>
                      <img
                        className='md:max-w-none w-[100%] lg:w-[80%] rounded border-[2px] border-slate-500'
                        src={"/images/template-html.png"}
                        alt='Features bg'
                      />
                      <img
                        className='md:max-w-none absolute w-[80%] lg:w-[60%] right-[10%] lg:right-0 top-48 lg:top-16 rounded border-[2px] border-slate-500'
                        src={"/images/template-json.png"}
                        alt='Features bg'
                      />
                      <div className='md:max-w-none w-[100%] lg:w-[70%] relative left-0 lg:left-[15%] mt-[12rem] lg:-mt-3 rounded bg-blue-600 text-white text-left leading-7 font-semibold px-4 py-4'>
                        <div className='mb-3'>
                          <span className='font-bold'>POST</span> https://
                          <span className='font-bold'>YOUR_DOMAIN</span>
                          /pdf-created
                        </div>
                        https://d1oukgrfj4kf7j.cloudfront.net
                        <br />
                        /ba7e0621-0d6b-4db7-aaa4-ac55855cc.pdf
                      </div>
                    </div>
                  </Transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
