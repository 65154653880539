import { getRegisterUrl } from "../../../utils/UrlUtils";
import Button from "./utils/Button";

export const Hero = ({
  nextDivScrollHandler,
}: {
  nextDivScrollHandler: any;
}) => {
  function handleStartFreeTrial() {
    window.location.href = getRegisterUrl();
  }

  return (
    <section className='relative overflow-hidden'>
      <div className='absolute left-1/2 transform -translate-x-1/2 top-60 pointer-events-none'>
        <svg
          width='1360'
          height='578'
          viewBox='0 0 1360 578'
          xmlns='http://www.w3.org/2000/svg'
        >
          <defs>
            <linearGradient
              x1='50%'
              y1='0%'
              x2='50%'
              y2='100%'
              id='illustration-01'
            >
              <stop stopColor='#f3f4f6' offset='0%' />
              <stop stopColor='#e5e7eb' offset='100%' />
            </linearGradient>
          </defs>
          <g fill='url(#illustration-01)' fillRule='evenodd'>
            <circle cx='1232' cy='128' r='128' />
            <circle cx='155' cy='443' r='64' />
          </g>
        </svg>
      </div>

      <div className='max-w-6xl mx-auto px-4 sm:px-6 relative z-10'>
        {/* Hero content */}
        <div className='pt-32 pb-12 md:pt-40 md:pb-20'>
          {/* Section header */}
          <div className='text-center pb-12 md:pb-16'>
            <h1 className='text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-6'>
              Powerful yet easy to use
              <br />{" "}
              <span className='bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400'>
                HTML to PDF API
              </span>
            </h1>
            <div className='max-w-4xl mx-auto'>
              <p className='text-xl text-gray-600 mb-8'>
                Combine custom templates with dynamic data and create tailored
                PDFs. <br />
                Start generating PDFs in No Time!
              </p>
              <div className='max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center'>
                <Button
                  id='hero-cta-register'
                  color='secondary'
                  size='large'
                  text='Get Started for Free'
                  width='max'
                  className='mb-4 sm:mb-0 sm:w-auto'
                  onClick={handleStartFreeTrial}
                />
                <Button
                  id='hero-explore-features'
                  color='black'
                  size='large'
                  text='Explore Features'
                  width='max'
                  className='sm:w-auto sm:ml-4'
                  onClick={nextDivScrollHandler}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Hero;
