import Icon from "./utils/Icon";

type Feature = {
  heading: string;
  description: string;
  icon: string;
};

const features: Feature[] = [
  {
    heading: "Seamless integration",
    icon: "bi-diagram-2",
    description:
      "Start generating PDFs in minutes, with no complex setup or payment method required",
  },
  {
    heading: "Dynamic Template Creation",
    icon: "bi-file-earmark-code",
    description:
      "Create highly customizable templates using <a href='https://handlebarsjs.com/guide/#installation' target='_blank' class='font-bold'>Handlebars</a>, the industry-standard templating engine.",
  },
  {
    heading: "Personalized PDFs",
    icon: "bi-shuffle",
    description:
      "Effortlessly incorporate dynamic data into your documents, ensuring every PDF is tailored to perfection",
  },
  {
    heading: "Browser Based PDF Engine",
    icon: "bi-cpu",
    description:
      "Our PDF generator relies on real web browsers to deliver the highest-quality results",
  },
  {
    heading: "Controlled User Access",
    icon: "bi-person-lines-fill",
    description:
      "Easily manage user access and permissions within your workspace for a secure collaboration environment",
  },
  {
    heading: "Professional support",
    icon: "bi-info-circle",
    description:
      "Receive prompt, expert assistance for any issues or inquiries. We're here to help with all your needs",
  },
];

const FeaturesBlocks = () => {
  return (
    <section className='relative'>
      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div
        className='absolute inset-0 top-1/2 md:mt-24 lg:mt-0 bg-gray-900 pointer-events-none'
        aria-hidden='true'
      ></div>
      <div className='absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 bg-gray-200 transform translate-y-1/2'></div>

      <div className='relative max-w-6xl mx-auto px-4 sm:px-6'>
        <div className='py-12 md:py-20'>
          {/* Section header */}
          <div className='max-w-3xl mx-auto text-center pb-12 md:pb-20'>
            <h2 className='h2 mb-3'>Key Features of DynamoPDF</h2>
            <p className='text-xl text-gray-600'>
              Discover What Makes DynamoPDF So Effective
            </p>
          </div>

          {/* Items */}

          <div className='max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none'>
            {/* 1st item */}
            {features.map((feature: Feature) => (
              <div
                className='relative flex flex-col items-center p-6 bg-white rounded shadow-xl'
                key={feature.heading}
              >
                <div className='rounded-full bg-blue-600 w-14 h-14 p-1 mb-3 text-center'>
                  <Icon
                    className={`text-white ${feature.icon} text-3xl`}
                    size='3xl'
                  />
                </div>
                <h4 className='text-xl font-bold leading-snug tracking-tight mb-1'>
                  {feature.heading}
                </h4>
                <p
                  className='text-gray-600 text-center'
                  dangerouslySetInnerHTML={{ __html: feature.description }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesBlocks;
