import { useRef, useState } from "react";

import { getRegisterUrl } from "../../utils/UrlUtils";
import PricingPlans, { PlanDetails } from "../components/app/PricingPlans";
import ContactUs from "../components/layout/ContactUs";
import Features from "../components/layout/Features";
import FeaturesBlocks from "../components/layout/FeaturesBlocks";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Hero from "../components/layout/Hero";
import {
  SimpleToastManager,
  ToastProps,
} from "../components/layout/utils/Toast";

export { Page };

function Page() {
  const featuresRef = useRef<any>(null);
  const [toast, setToast] = useState<ToastProps>();

  function scrollToFeatures() {
    const element = featuresRef.current;

    let topOffset = 40;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - topOffset;

    window.scrollTo({
      top: offsetPosition,
    });
  }

  function handlePlanSelected(planDetails: PlanDetails) {
    console.log(planDetails); // TODO Cache planDetails in sessionStorage to use later when creating a workspace

    window.location.href = getRegisterUrl();
  }

  function handleSuccessfulContact() {
    setToast({ type: "success", message: "Message sent" });
  }

  function handleFailedContact() {
    setToast({ type: "error", message: "Something went wrong" });
  }

  return (
    <div className='font-inter antialiased tracking-tight bg-gradient-to-b from-gray-100 to-white'>
      <Header />
      <main className='grow'>
        <Hero nextDivScrollHandler={scrollToFeatures} />
        <div ref={featuresRef}>
          <Features />
        </div>
        <FeaturesBlocks />
        <div className='max-w-7xl mx-auto mt-20 pb-20 px-5 relative'>
          <h2 className='h2 mb-3 text-center'>
            Flexible Pricing for Every Business
          </h2>
          <p className='text-xl text-gray-600 text-center'>
            Choose the Perfect Plan for Your Company Size
          </p>
          <PricingPlans
            onPlanChanged={handlePlanSelected}
            buttonText='Start free trial'
          />
          <div className='absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 bg-gray-200 transform translate-y-1/2'></div>
        </div>
        <div className='bg-gray-900 pt-20 pb-20'>
          <div className='max-w-lg mx-auto px-5'>
            <ContactUs
              inverseColors
              onSuccess={handleSuccessfulContact}
              onFail={handleFailedContact}
            />
          </div>
        </div>
      </main>

      <Footer />

      <SimpleToastManager toast={toast} setToast={setToast} />
    </div>
  );
}
